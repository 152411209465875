<template>
  <div>
    <bread-cum
      v-bind:breadcrumbItems="['การเงิน', 'แจ้งฝากถอน', 'สร้างยอดฝาก']"
    />
    <ValidationObserver ref="observer">
      <div class="row mx-0 py-2 rounded">
        <div class="col-12 mb-2">
          <ValidationProvider rules="required|numeric" v-slot="v">
            <label for="userId" class="mb-0"
              ><i class="fas fa-user"></i> #สมาชิก</label
            >
            <input
              id="userId"
              v-model.number="form.userId"
              type="text"
              class="form-control"
              placeholder="#สมาชิก"
            />
            <span class="text-danger">{{ v.errors[0] }}</span>
          </ValidationProvider>
        </div>

        <div class="col-12 mb-2">
          <ValidationProvider rules="required" v-slot="v">
            <label class="mb-0" for="bankCode">ธนาคารฝาก:</label>
            <select class="form-control" id="bankCode" v-model="form.bankCode">
              <option value="SCB">ธ.ไทยพาณิชย์</option>
              <option value="KBANK">ธ.กสิกรไทย</option>
            </select>
            <span class="text-danger">{{ v.errors[0] }}</span>
          </ValidationProvider>
        </div>

        <div class="col-12 mb-2">
          <ValidationProvider rules="required|decimal|min_value:1" v-slot="v">
            <label for="amount" class="mb-0">จำนวนฝาก:</label>
            <input
              id="amount"
              v-model="form.amount"
              type="text"
              class="form-control"
              placeholder="จำนวนฝาก"
            />
            <span class="text-danger">{{ v.errors[0] }}</span>
          </ValidationProvider>
        </div>

        <div class="col-6">
          <button class="btn btn-secondary w-100" @click="$router.go(-1)">
            ยกเลิก
          </button>
        </div>
        <div class="col-6">
          <button class="btn btn-primary w-100" @click="create" v-show="canBuildDeposit">
            สร้างยอดฝาก
          </button>
        </div>
      </div>
    </ValidationObserver>
  </div>
</template>

<script>
export default {
  data() {
    return {
      form: {
        bankCode: "SCB",
      },
    }
  },
  methods: {
    async create() {
      this.swal.processing()

      this.form.userId = parseInt(this.form.userId)

      try {
        await this.axios({
          method: "post",
          url: "users/deposit/request",
          data: this.form,
        })

        this.$swal({
          icon: "success",
          title: "สำเร็จ",
        }).then(() => {
          // this.$router.push("/credit/bank/transfer/request")
          this.$router.go(-1)
        })
      } catch (err) {
        console.log(err)
        this.swal.swalError(err.response.data.description)
      }
    },
  },
  mounted() {
    this.form.userId = this.$route.query.userId
  },
  computed:{
    canBuildDeposit(){
      return this.$store.state.auth.user?.userData?.permissions?.includes("bank_transfer_request_build_deposit") 
    }, 
  }
}
</script>